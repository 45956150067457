import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import { Card, Button } from "react-bootstrap"
import Layout from "../components/layout"

export default function Games() {
  return (
    <Layout>

        <Helmet>
          <meta charSet="utf-8" />
          <title>Games | CheePlus</title>
          <link rel="canonical" href="https://www.cheeplus.com/games" />
        </Helmet>



      <h4>Our Games</h4>


      <Card style={{ width: '18rem' }}>
        <Card.Img variant="top" src="https://i.postimg.cc/jq78XtnX/icon-PTB-2-512x512.png" />
        <Card.Body>
          <Card.Title>Pop The Balloon</Card.Title>
          <Card.Text>
          A modern word game to challenge your brain, fingers, and most importantly, your vocabulary strength.
          </Card.Text>
          <Button variant="primary" href="/games/pop-the-balloon/">View</Button>
        </Card.Body>
      </Card>

    </Layout>
  )
}


export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`